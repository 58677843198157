/* AuthProvider.module.css */
.sessionTimeoutOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .timeoutModal {
    background: white;
    border-radius: 8px;
    padding: 2rem;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-out;
  }
  
  .timeoutTitle {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    color: #333;
    text-align: center;
  }
  
  .timeoutMessage {
    margin: 0 0 0.5rem;
    font-size: 1rem;
    color: #666;
    text-align: center;
  }
  
  .timeDisplay {
    font-weight: bold;
    color: #dc3545;
    font-family: monospace;
  }
  
  .timeoutInfo {
    margin: 0 0 1.5rem;
    font-size: 0.9rem;
    color: #666;
    text-align: center;
  }
  
  .timeoutActions {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .stayButton {
    background: #007bff;
    color: white;
  }
  
  .stayButton:hover {
    background: #0056b3;
  }
  
  .logoutButton {
    background: #f8f9fa;
    color: #333;
    border: 1px solid #ddd;
  }
  
  .logoutButton:hover {
    background: #e9ecef;
  }
  
  .loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loadingContainer {
    text-align: center;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 1rem;
  }
  
  .loadingText {
    margin: 0;
    color: #666;
    font-size: 1rem;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
} 