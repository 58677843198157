/* Wrapper Styling */
.privacyWrapper {
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 70px;
    padding: 80px 30px;
    background: linear-gradient(135deg, #ffffff 60%, #f5f5f5 100%);
    min-height: 100vh;
    font-family: 'Georgia', serif;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(212, 175, 55, 0.2);
    border-radius: 15px;
  }
  
  /* Header Styling */
  .header {
    text-align: center;
    margin-bottom: 60px;
    position: relative;
  }
  
  .title {
    font-size: 3rem;
    color: #D4AF37;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .effectiveDate {
    font-size: 1.2rem;
    color: #555555;
    font-style: italic;
    margin: 0;
  }
  
  .header::after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background-color: #D4AF37;
    margin: 20px auto;
  }
  
  /* Content Container */
  .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  /* Card Styling */
  .card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.05);
    border-left: 5px solid #D4AF37;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    animation: slideIn 0.6s ease-out forwards;
  }
  
  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #D4AF37;
    opacity: 0.2;
  }
  
  .card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
  }
  
  /* Animation sequence for cards */
  .card:nth-child(1) { animation-delay: 0.1s; }
  .card:nth-child(2) { animation-delay: 0.2s; }
  .card:nth-child(3) { animation-delay: 0.3s; }
  .card:nth-child(4) { animation-delay: 0.4s; }
  .card:nth-child(5) { animation-delay: 0.5s; }
  .card:nth-child(6) { animation-delay: 0.6s; }
  .card:nth-child(7) { animation-delay: 0.7s; }
  .card:nth-child(8) { animation-delay: 0.8s; }
  
  /* Section Title */
  .sectionTitle {
    font-size: 2rem;
    color: #000000;
    margin-bottom: 20px;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    letter-spacing: 1px;
  }
  
  .sectionTitle::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 3px;
    background-color: #D4AF37;
    bottom: 0;
    left: 0;
    transition: width 0.3s ease;
  }
  
  .card:hover .sectionTitle::after {
    width: 100px;
  }
  
  /* Introductory Text */
  .intro {
    font-size: 1.15rem;
    color: #333333;
    line-height: 1.9;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 300;
    border-left: 2px solid #D4AF37;
    padding-left: 15px;
  }
  
  /* List Styling */
  .list {
    list-style: none;
    padding: 0;
    margin: 15px 0;
  }
  
  .list li {
    font-size: 1.1rem;
    color: #444444;
    margin-bottom: 15px;
    padding-left: 30px;
    position: relative;
    transition: color 0.3s ease;
  }
  
  .list li:hover {
    color: #D4AF37;
  }
  
  .list li::before {
    content: '✦';
    color: #D4AF37;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 2px;
    transition: transform 0.3s ease;
  }
  
  .list li:hover::before {
    transform: scale(1.2);
  }
  
  .list li span {
    font-weight: 600;
    color: #000000;
  }
  
  /* Link Styling */
  .link {
    color: #D4AF37;
    text-decoration: none;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .link:hover {
    background-color: #D4AF37;
    color: #ffffff;
  }
  
  /* Contact Grid */
  .contactGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 25px;
    margin-top: 25px;
    padding: 20px 0;
    border-top: 1px solid #e5e5e5;
  }
  
  /* Contact Card */
  .contactCard {
    display: flex;
    align-items: flex-start;
    background-color: #fafafa;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    transition: all 0.3s ease;
    width: 100%;
    word-break: break-word;
  }
  
  .contactCard:hover {
    background-color: #ffffff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
    transform: scale(1.02);
  }
  
  /* Icon Wrapper - Fixed to ensure perfect circles */
  .iconWrapper {
    width: 60px;
    height: 60px;
    min-width: 60px; /* Ensure fixed width */
    background-color: #D4AF37;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    box-shadow: 0 4px 10px rgba(212, 175, 55, 0.3);
    transition: background-color 0.3s ease;
    flex-shrink: 0; /* Prevent shrinking */
  }
  
  .contactCard:hover .iconWrapper {
    background-color: #b8962e;
  }
  
  .iconWrapper svg {
    color: #ffffff;
  }
  
  /* Contact Text */
  .contactText {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .contactText p {
    font-size: 1rem;
    color: #666666;
    margin: 0;
    line-height: 1.5;
  }
  
  .contactText p:first-child {
    font-weight: 500;
    color: #555555;
  }
  
  .contactText h3 {
    font-size: 1.25rem;
    color: #000000;
    margin: 5px 0 0;
    font-weight: 600;
    transition: color 0.3s ease;
    word-break: break-all; /* Ensure email breaks properly */
  }
  
  .contactCard:hover .contactText h3 {
    color: #D4AF37;
  }
  
  /* Email link styling */
  .emailLink {
    color: #000000;
    text-decoration: none;
    transition: color 0.3s ease;
    word-break: break-all;
    font-weight: 600;
    font-size: 1.25rem;
    display: inline-block;
    margin-top: 5px;
  }
  
  .contactCard:hover .emailLink {
    color: #D4AF37;
  }
  
  /* Background styling */
  .privacyWrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top, rgba(212, 175, 55, 0.1), transparent 70%);
    z-index: -1;
  }
  
  /* Animations */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .contactGrid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 1024px) {
    .privacyWrapper {
      padding: 60px 20px;
    }
  
    .title {
      font-size: 2.5rem;
    }
  
    .sectionTitle {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 768px) {
    .card {
      padding: 20px;
    }
  
    .title {
      font-size: 2.2rem;
    }
  
    .sectionTitle {
      font-size: 1.6rem;
    }
  
    .intro {
      font-size: 1rem;
    }
  
    .list li {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .privacyWrapper {
      padding: 40px 15px;
    }
  
    .title {
      font-size: 1.8rem;
    }
  
    .sectionTitle {
      font-size: 1.4rem;
    }
  
    .contactCard {
      padding: 15px;
    }
  
    .iconWrapper {
      width: 50px;
      height: 50px;
      min-width: 50px;
    }
  
    .contactText h3, .emailLink {
      font-size: 1.1rem;
    }
  }