.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.loginCard {
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
  transition: transform 0.2s ease-in-out;
}

.loginCard:hover {
  transform: translateY(-2px);
}

.loginHeading {
  text-align: center;
  margin-bottom: 30px;
  color: #2c3e50;
  font-size: 28px;
  font-weight: 600;
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #34495e;
  font-size: 14px;
}

.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out;
}

.input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.input::placeholder {
  color: #95a5a6;
}

.rememberMeContainer {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.rememberMeLabel {
  margin-left: 8px;
  color: #555;
  font-size: 14px;
  cursor: pointer;
}

.loginButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.loginButton:hover:not(.disabledButton) {
  background-color: #0056b3;
}

.disabledButton {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.error {
  color: #dc3545;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  background-color: #fee;
  padding: 8px;
  border-radius: 4px;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerDot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  margin: 0 4px;
  animation: bounce 0.6s infinite alternate;
}

.spinnerDot:nth-child(2) {
  animation-delay: 0.2s;
}

.spinnerDot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}