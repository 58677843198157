/* BlogComponent.css */
.blog-showcase {
    --primary-color: #4a6bff;
    --secondary-color: #5d5dff;
    --text-color: #2d3748;
    --text-secondary: #718096;
    --bg-color: #ffffff;
    --bg-secondary: #f7fafc;
    --border-color: #e2e8f0;
    --success-color: #48bb78;
    --error-color: #f56565;
    --transition: all 0.3s ease;
    
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .blog-container {
    background-color: var(--bg-color);
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: var(--transition);
  }
  
  .blog-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12);
  }
  
  .blog-header {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .blog-header h2 {
    color: var(--text-color);
    font-weight: 700;
    margin-bottom: 0;
  }
  
  .blog-filters {
    display: flex;
    padding: 1rem 2rem;
    background-color: var(--bg-secondary);
    border-bottom: 1px solid var(--border-color);
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1.5rem 2rem;
  }
  
  .blog-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem 2rem;
  }
  
  .blog-card {
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    transition: var(--transition);
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .blog-card-image {
    position: relative;
    overflow: hidden;
    height: 200px;
  }
  
  .blog-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition);
  }
  
  .blog-card:hover .blog-card-image img {
    transform: scale(1.05);
  }
  
  .blog-card-content {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .blog-card-meta {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
  }
  
  .blog-card-meta .category {
    color: var(--primary-color);
    font-weight: 600;
    margin-right: 1rem;
  }
  
  .blog-card-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    color: var(--text-color);
    line-height: 1.4;
  }
  
  .blog-card-title a {
    color: inherit;
    text-decoration: none;
    transition: var(--transition);
  }
  
  .blog-card-title a:hover {
    color: var(--primary-color);
  }
  
  .blog-card-excerpt {
    color: var(--text-secondary);
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex-grow: 1;
  }
  
  .blog-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }
  
  .blog-author {
    display: flex;
    align-items: center;
  }
  
  .blog-author-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.5rem;
  }
  
  .blog-author-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .blog-author-name {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-color);
  }
  
  .blog-date {
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
  
  .blog-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
  }
  
  .blog-tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
    transition: var(--transition);
  }
  
  .blog-tag:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  .blog-pagination {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    gap: 0.5rem;
  }
  
  .blog-pagination .page-item .page-link {
    color: var(--text-color);
    border-color: var(--border-color);
    transition: var(--transition);
  }
  
  .blog-pagination .page-item.active .page-link {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }
  
  .blog-pagination .page-item .page-link:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }
  
  .blog-read-more {
    display: inline-block;
    font-weight: 600;
    color: var(--primary-color);
    position: relative;
    transition: var(--transition);
  }
  
  .blog-read-more:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: var(--primary-color);
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }
  
  .blog-read-more:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  .blog-search {
    position: relative;
    flex: 1;
    max-width: 320px;
  }
  
  .blog-search input {
    border-radius: 20px;
    padding-left: 2.5rem;
    padding-right: 1rem;
    border: 1px solid var(--border-color);
    transition: var(--transition);
  }
  
  .blog-search input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(74, 107, 255, 0.15);
  }
  
  .blog-search i {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-secondary);
  }
  
  .blog-view-toggle {
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    overflow: hidden;
  }
  
  .blog-view-toggle button {
    background: none;
    border: none;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    transition: var(--transition);
    color: var(--text-secondary);
  }
  
  .blog-view-toggle button.active {
    background-color: var(--primary-color);
    color: white;
  }
  
  .blog-view-toggle button:hover:not(.active) {
    background-color: var(--bg-secondary);
  }
  
  .blog-featured {
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
    border-radius: 8px;
  }
  
  .blog-featured-image {
    height: 400px;
    width: 100%;
    position: relative;
  }
  
  .blog-featured-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .blog-featured-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    background: linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0));
    color: white;
  }
  
  .blog-featured-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .blog-featured-title a {
    color: white;
    text-decoration: none;
  }
  
  .blog-featured-meta {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .category-filter {
    margin-right: 0.5rem;
    cursor: pointer;
    transition: var(--transition);
    font-weight: 500;
    color: var(--text-secondary);
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
  }
  
  .category-filter.active {
    background-color: var(--primary-color);
    color: white;
  }
  
  .category-filter:hover:not(.active) {
    color: var(--primary-color);
  }
  
  .blog-loading {
    padding: 3rem;
    text-align: center;
    color: var(--text-secondary);
  }
  
  .blog-error {
    padding: 3rem;
    text-align: center;
    color: var(--error-color);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .blog-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    
    .blog-featured-image {
      height: 300px;
    }
    
    .blog-featured-title {
      font-size: 1.5rem;
    }
    
    .blog-header {
      flex-direction: column;
      gap: 1rem;
    }
    
    .blog-search {
      max-width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .blog-grid {
      grid-template-columns: 1fr;
    }
    
    .blog-featured-image {
      height: 250px;
    }
    
    .blog-featured-title {
      font-size: 1.25rem;
    }
  }
  
  /* Theme variations */
  .blog-showcase.dark-theme {
    --text-color: #e2e8f0;
    --text-secondary: #a0aec0;
    --bg-color: #1a202c;
    --bg-secondary: #2d3748;
    --border-color: #4a5568;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .blog-card {
    animation: fadeIn 0.5s ease forwards;
  }
  
  .blog-card:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .blog-card:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .blog-card:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  .skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  .blog-skeleton-image {
    height: 200px;
    border-radius: 8px 8px 0 0;
  }
  
  .blog-skeleton-title {
    height: 24px;
    margin: 1rem 0;
    width: 80%;
    border-radius: 4px;
  }
  
  .blog-skeleton-text {
    height: 16px;
    margin-bottom: 0.5rem;
    border-radius: 4px;
  }
  
  .blog-skeleton-text:last-child {
    width: 60%;
  }