/* Reset default margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
}

/* General Navbar Styles */
.navbar {
 background-color: rgba(0, 0, 0, 0.5); /* Transparent from start */
  backdrop-filter: blur(10px); /* Optional: Keeps a subtle blur effect */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2000;
  transition: all 0.3s ease;
  border-bottom: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
}

/* .navbar.scrolled {
  background-color: rgba(25, 25, 25, 0.75);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
} */

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;

}

.logo {
  width: 140px;
  height: auto;
  opacity: 0.9;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.15));
  position: relative;
  transition: all 0.3s ease;
}

.navLinks {
  display: flex;
  gap: 10px;
  align-items: center;
}


.navLink {
  background: none;
  position: relative;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 300;
  font-family: "Poppins", sans-serif; /* Sleek & modern font */
  transition: all 0.3s ease;
  white-space: nowrap;
  padding: 12px 18px;
  text-decoration: none; /* Remove default underline */
  letter-spacing: 0.5px; /* Slight spacing for elegance */
}
.navLink::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px; /* Adjusted for subtle effect */
  width: 0%;
  height: 2px;
  background-color: #FFD700; /* Gold underline */
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}
  
 
  
.navLink:hover {
  color: #ffd700;
}


.btnPrimary {
  background-color: #D4AF37; /* Gold */
  color: #000; /* Black text for contrast */
  border: 2px solid #B8860B; /* Darker gold border */
  padding: 6px 15px;
  border-radius: 8px; /* Slightly more rounded corners for a premium look */
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  box-shadow: 0px 4px 10px rgba(212, 175, 55, 0.3); /* Soft golden glow */
  position: relative;
  overflow: hidden;
}

.btnPrimary:hover {
  background-color: #B8860B; /* Slightly darker gold on hover */
  color: #000000;
  transform: scale(1.05); /* Subtle scaling effect */
  box-shadow: 0px 6px 15px rgba(212, 175, 55, 0.5);
}


.hamburger {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 2200;
}

/* Dropdown Styles */
.dropdownButton {
  font-size: inherit; /* Inherit from parent */
  font-weight: inherit; /* Inherit from parent */
  color: inherit; /* Inherit from parent */
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
  font-family: inherit; /* Inherit from parent */
}

.dropdown button:hover {
  color: #ffd700;
}

.dropdown {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
  border-radius: 5px;
  display: none;
  flex-direction: column;
  padding: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 200px;
}

.dropdown:hover .dropdownMenu {
  display: flex;
}

.dropdownItem {
  background: none;
  border: none;
  color: white;
  padding: 12px 20px;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 6px;
}

.dropdownItem:hover {
  background: rgba(255, 215, 0, 0.2);
  color: #ffd700;
}

@media (max-width: 1024px) {
  .navLinks {
    gap: 30px;
  }
  .container {
    padding: 15px 30px;
  }
  
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px 15px;
  }
  .container {
    padding: 10px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .hamburger {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .navLinks {
    font-family: 'Helvetica Now Display', Helvetica, Arial, sans-serif;
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.95);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    z-index: 2100;
  }

  .btnPrimary {
    position: static;
    right: 20px;  /* Keep it inside the screen */
    top: 15px;
    font-size: 14px; 
    padding: 6px 10px;
    font-size: 16px;
    padding: 6px 12px;
    margin-top: 20px; /* Space from other menu items */
    box-shadow: 0px 4px 10px rgba(212, 175, 55, 0.3);

  }
  .navLinks.show {
    left: 0;
  }
  .navLink {
    font-size: 18px;
    padding: 15px;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .dropdown-menu {
    background: rgba(0, 0, 0, 0.95); /* Dark background with slight transparency */
    border-radius: 10px;
    padding: 10px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    width: 90%; /* Adjust width for mobile */
    max-width: 280px; /* Prevent it from being too wide */
    margin: 10px auto; /* Center it properly */
  }

  .dropdown-item {
    color: #fff;
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background 0.2s ease-in-out;
  }

  .dropdown-item:hover {
    background: rgba(255, 255, 255, 0.15); /* Lighter hover effect */
    border-radius: 5px;
  }

  /* Make sure the dropdown is positioned properly */
  .dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}


@media (max-width: 480px) {
  .container {
    padding: 10px;
  }
  .logo {
    width: 100px;
  }
  .navLink {
    font-size: 14px;
  }
  .btnPrimary {
    font-size: 14px;
    padding: 4px 10px;
    margin-top: 15px;
  }
}
