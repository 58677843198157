.modernFooter {
    position: relative;
    background-color: rgba(0, 0, 0, 0.85); /* Dark overlay base */
    overflow-x: hidden;
}

.modernFooter::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/footer-bg.webp") repeat;
    opacity: 0.14; /* Slightly more visible but still subtle */
    z-index: 1;
}

/* Make sure all content appears above the background */
.modernFooter > * {
    position: relative;
    z-index: 2;
}

.footerWave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    filter: drop-shadow(0 -4px 6px rgba(0, 0, 0, 0.2));
}

.footerWave svg path {
    fill: #ecac5e; /* Dark golden color */
}

.mainCardContainer {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.mainCard {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    padding: 4rem;
    text-align: center;
    max-width: 800px;
    width: 100%;
    border: 3px solid rgba(218, 165, 32, 0.2); /* Subtle gold border */
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.cardTitle {
    color: #FFD700; /* Bright gold */
    font-size: 3.2rem;
    margin-bottom: 2rem;
    font-weight: 800;
    letter-spacing: -0.02em;
    line-height: 1.2;
}

.cardDescription {
    color: #F5F5F5; /* Off-white */
    font-size: 1.25rem;
    line-height: 1.8;
    margin-bottom: 2.5rem;
    font-weight: 400;
}

:global(.contact-btn) {
    background: #ecac5e; /* Golden rod */
    color: #000000;
    padding: 1.25rem 3rem;
    border-radius: 50px;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.1rem;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: 2px solid transparent;
}

:global(.contact-btn:hover) {
    background: transparent;
    color: #FFD700;
    border-color: #ecac5e;
    transform: translateY(-4px);
    box-shadow: 0 8px 20px rgba(218, 165, 32, 0.2);
}

.contactInfoContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 3rem;
    padding: 0 4rem;
    margin-bottom: 5rem;
}

.contactCard {
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1px);
    padding: 3rem 2rem;
    text-align: center;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: 3px solid rgba(218, 165, 32, 0.1);
}

.contactCard:hover {
    transform: translateY(-8px);
    background: rgba(0, 0, 0, 0.6);
    border-color: rgba(218, 165, 32, 0.3);
}

.contactIcon {
    background: #ecac5e;
    color: #ffffff;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    font-size: 1.8rem;
    transition: all 0.3s ease;
}

.contactCard:hover .contactIcon {
    transform: scale(1.1);
    background: #DAA520;
}

.contactCard h4 {
    color: #FFD700;
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    font-weight: 700;
}

.contactCard p,
.contactCard a {
    color: #F5F5F5;
    margin: 0.75rem 0;
    font-size: 1.1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    line-height: 1.6;
}

.contactCard a:hover {
    color: #FFD700;
}

.footerLinksGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 4rem;
    padding: 0 4rem;
    margin-bottom: 4rem;
}

.sectionTitle {
    color: #FFD700;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-weight: 700;
    position: relative;
    padding-bottom: 1rem;
}

.sectionTitle::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 3px;
    background: #ecac5e;
    border-radius: 2px;
}

.linkList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.linkList li {
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;
}

.bulletIcon {
    color: #ecac5e;
    margin-right: 0.75rem;
    font-size: 0.9rem;
}

.linkList a {
    color: #F5F5F5;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    font-size: 1.1rem;
    opacity: 0.9;
}

.linkList a:hover {
    color: #FFD700;
    opacity: 1;
    transform: translateX(8px);
}

.socialContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.socialLinks {
    display: flex;
    gap: 2rem;
}

.socialIcon {
    background: #B8860B;
    color: #ffffff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: 2px solid transparent;
}

.socialIcon:hover {
    background: transparent;
    color: #FFD700;
    border-color: #DAA520;
    transform: translateY(-4px) scale(1.1);
}

.copyrightSection {
    text-align: center;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.8);
    font-size: 1rem;
    color: #F5F5F5;
    border-top: 1px solid rgba(218, 165, 32, 0.1);
}

/* Media queries */
@media (max-width: 768px) {
    .mainCard {
        margin: 0 1rem;
        padding: 2.5rem;
        transform: translateY(-40px);
    }
    
    .cardTitle {
        font-size: 2.5rem;
    }
    
    .contactInfoContainer,
    .footerLinksGrid {
        padding: 0 2rem;
    }
    
    .footerLinksGrid {
        gap: 3rem;
    }
}

@media (max-width: 480px) {
    .cardTitle {
        font-size: 2rem;
    }
    
    .cardDescription {
        font-size: 1.1rem;
    }
    
    .contactInfoContainer,
    .footerLinksGrid {
        padding: 0 1.5rem;
    }
}