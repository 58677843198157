.floatingChatContainer {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chatOptions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chatOption {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chatOption:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.whatsapp {
    background-color: #25D366;
}

.phone {
    background-color: #1e64cc;
}

.chatToggle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    background-color: #d4af37;
    color: white;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chatToggle:hover {
    transform: scale(1.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.active {
    background-color: #dc3545;
    transform: rotate(180deg);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .floatingChatContainer {
        bottom: 1rem;
        right: 1rem;
    }

    .chatOption {
        width: 45px;
        height: 45px;
        font-size: 1.3rem;
    }

    .chatToggle {
        width: 55px;
        height: 55px;
        font-size: 1.6rem;
    }
}