/* PropertyForm.module.css */

/* Root Variables for Consistency */
:root {
  --primary-color: #3182ce;
  --primary-hover: #2b6cb0;
  --secondary-color: #4a5568;
  --error-color: #e53e3e;
  --success-color: #38a169;
  --background-light: #f9fafb;
  --white: #ffffff;
  --gray-100: #eef3f7;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e0;
  --gray-400: #a0aec0;
  --gray-600: #718096;
  --gray-800: #2d3748;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 12px rgba(0, 0, 0, 0.08);
  --shadow-lg: 0 10px 20px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
  --border-radius-sm: 4px;
  --border-radius-md: 6px;
  --border-radius-lg: 12px;
}

/* General Container Styling */
.container {
  max-width: 95%;
  margin: 0 auto;
  padding: 10px;
  background: var(--background-light);
  min-height: auto;
  position: relative;
  transition: var(--transition);
}

.container:hover {
  background: linear-gradient(135deg, #f9fafb, #edf2f7);
}

/* Heading Styling */
.heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--gray-800);
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  background: linear-gradient(to right, var(--primary-color), var(--gray-800));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: var(--transition);
}

/* Main Form Container */
.propertyForm {
  background: var(--white);
  border-radius: var(--border-radius-lg);
  padding: 15px;
  box-shadow: var(--shadow-md);
  border: 1px solid var(--gray-200);
  position: relative;
  transition: var(--transition);
}

/* .propertyForm::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(49, 130, 206, 0.1), transparent);
  opacity: 0;
  transition: opacity 0.5s ease;
} */

.propertyForm:hover::before {
  opacity: 1;
}

.configurationGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.configurationGroup .formGroup {
  flex: 1 1 30%;
  min-width: 200px;
}


/* Add this new class below the existing .removeImageBtn */
.removeConfigBtn {
  background: linear-gradient(135deg, var(--error-color), #c53030);
  color: var(--white);
  border: none;
  padding: 8px 16px;
  border-radius: var(--border-radius-md);
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  align-self: center; /* Center it within the flex container */
}

.removeConfigBtn:hover {
  background: linear-gradient(135deg, #c53030, var(--error-color));
  box-shadow: var(--shadow-md);
  transform: translateY(-1px);
}

.removeConfigBtn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.6s ease, height 0.6s ease;
}

.removeConfigBtn:hover::after {
  width: 150px;
  height: 150px;
}

.removeConfigBtn:active::after {
  width: 200px;
  height: 200px;
  transition: width 0.2s ease, height 0.2s ease;
}

.propertyForm:hover {
  box-shadow: var(--shadow-lg);
  transform: translateY(-2px);
}

/* Form Group Styling */
.formGroup {
  margin-bottom: 10px;
  position: relative;
  padding: 5px;
  transition: var(--transition);
}

.formGroup:hover {
  background: var(--gray-100);
  border-radius: var(--border-radius-md);
}

/* Side-by-Side Form Groups */
.formGroupSideBySide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 10px;
}

/* Label Styling */
.label {
  display: block;
  margin-bottom: 6px;
  font-weight: 600;
  color: var(--secondary-color);
  font-size: 13px;
  letter-spacing: 0.2px;
  transition: var(--transition);
  position: relative;
  padding-left: 15px;
}

.label::before {
  content: '•';
  position: absolute;
  left: 0;
  color: var(--primary-color);
  font-size: 14px;
}

/* Input Fields */
.input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-md);
  font-size: 13px;
  color: var(--gray-800);
  background: var(--white);
  transition: var(--transition);
  box-shadow: var(--shadow-sm);
  text-align-last: center;

}

.input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.25);
  background: var(--gray-100);
}

.input::placeholder {
  color: var(--gray-400);
  font-style: italic;
  opacity: 0.8;
}

/* Select Fields */
.select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-md);
  font-size: 13px;
  color: var(--gray-800);
  background: var(--white);
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%234a5568%22%20d%3D%22M4%206l4%204%204-4z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 10px center;
  transition: var(--transition);
  box-shadow: var(--shadow-sm);
}

.select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.25);
  background: var(--gray-100);
}

/* Textarea Styling */
.textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-md);
  font-size: 13px;
  color: var(--gray-800);
  background: var(--white);
  min-height: 80px;
  resize: vertical;
  transition: var(--transition);
  box-shadow: var(--shadow-sm);
}

.textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.25);
  background: var(--gray-100);
}

/* Error Styling */
.inputError {
  border-color: var(--error-color) !important;
  background: #fff5f5;
  box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.25) !important;
  animation: shake 0.3s ease;
}

.errorText {
  color: var(--error-color);
  font-size: 11px;
  margin-top: 4px;
  display: block;
  font-weight: 500;
  background: rgba(229, 62, 62, 0.1);
  padding: 2px 5px;
  border-radius: var(--border-radius-sm);
  transition: var(--transition);
}

.errorMessage {
  color: var(--error-color);
  font-size: 13px;
  margin-bottom: 10px;
  text-align: center;
  background: #fff5f5;
  padding: 8px;
  border-radius: var(--border-radius-md);
  border: 1px solid #feb2b2;
  font-weight: 500;
  box-shadow: var(--shadow-sm);
  animation: slideDown 0.4s ease;
}

/* Button Styling */
.button {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-hover));
  color: var(--white);
  border: none;
  padding: 10px 18px;
  border-radius: var(--border-radius-md);
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-md);
}

.button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.6s ease, height 0.6s ease;
}

.button:hover::after {
  width: 200px;
  height: 200px;
}

.button:hover {
  box-shadow: var(--shadow-lg);
  transform: translateY(-1px);
}

.buttonDisabled {
  background: var(--gray-200);
  color: var(--gray-400);
  cursor: not-allowed;
  box-shadow: none;
}

.buttonDisabled:hover {
  background: var(--gray-200);
  transform: none;
}

/* Payment Plan Section */
.paymentPlanHeading {
  margin-bottom: 10px;
  font-weight: 700;
  color: var(--gray-800);
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 6px;
  position: relative;
  transition: var(--transition);
}

.paymentPlanHeading::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 30px;
  height: 2px;
  background: var(--primary-hover);
  transition: width 0.4s ease;
}

.paymentPlanHeading:hover::after {
  width: 100%;
}

.paymentPlanGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 8px;
  background: var(--gray-100);
  border-radius: var(--border-radius-md);
  border: 1px dashed var(--gray-300);
}

.paymentField {
  margin-bottom: 8px;
  transition: var(--transition);
}

.paymentField:hover {
  transform: translateY(-1px);
}

.paymentField .label {
  font-size: 12px;
  color: var(--gray-600);
}

/* Image Preview Styling */
.imagePreviewItem {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: var(--radius-sm);
  overflow: hidden;
  border: 2px solid var(--border-color);
}

.imagePreviewItem img {
  width: 220px;
  height: 2 20px;
  object-fit: cover;
  display: block;
}

.removeImageBtn {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 0.75rem;
  transition: var(--transition);
}

.removeImageBtn:hover {
  background-color: var(--error-color);
  transform: scale(1.1);
}

.imagesGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  background-color: var(--bg-lighter);
  border-radius: var(--radius-md);
}

/* Help Text */
.helpText {
  font-size: 11px;
  color: var(--gray-600);
  margin-top: 4px;
  display: block;
  font-style: italic;
  background: var(--gray-100);
  padding: 2px 5px;
  border-radius: var(--border-radius-sm);
}

/* Checkbox Group */
.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 6px;
  background: var(--gray-100);
  border-radius: var(--border-radius-md);
  transition: var(--transition);
}

.checkboxGroup:hover {
  background: var(--white);
  box-shadow: var(--shadow-sm);
}

.checkboxGroup input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  accent-color: var(--primary-color);
  cursor: pointer;
  transition: var(--transition);
}

.checkboxLabel {
  font-weight: 500;
  color: var(--gray-800);
  font-size: 13px;
  transition: var(--transition);
}

/* Number Input Styling */
.formGroup input[type="number"],
.formGroupSideBySide input[type="number"] {
  appearance: textfield;
}

.formGroup input[type="number"]::-webkit-inner-spin-button,
.formGroup input[type="number"]::-webkit-outer-spin-button,
.formGroupSideBySide input[type="number"]::-webkit-inner-spin-button,
.formGroupSideBySide input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* File Input Styling */
.formGroup input[type="file"] {
  padding: 6px;
  border: 1px dashed var(--gray-300);
  border-radius: var(--border-radius-md);
  background: var(--gray-100);
  font-size: 12px;
  color: var(--gray-800);
  transition: var(--transition);
}

.formGroup input[type="file"]:hover {
  border-color: var(--primary-color);
  background: var(--white);
}

.formGroup input[type="file"]::-webkit-file-upload-button {
  background: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 6px 12px;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: var(--transition);
  font-weight: 500;
}

.formGroup input[type="file"]::-webkit-file-upload-button:hover {
  background: var(--primary-hover);
}

/* Animation Keyframes */
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-4px); }
  50% { transform: translateX(4px); }
  75% { transform: translateX(-4px); }
  100% { transform: translateX(0); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Section-Specific Styling */
.coordinatesGroup,
.seoSection,
.reraSection {
  border: 1px solid var(--gray-200);
  padding: 10px;
  border-radius: var(--border-radius-md);
  background: var(--gray-100);
  transition: var(--transition);
}

.coordinatesGroup:hover,
.seoSection:hover,
.reraSection:hover {
  background: var(--white);
  box-shadow: var(--shadow-sm);
}

/* Coordinates Group Side-by-Side */
.coordinatesGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

/* Disabled State */
.input:disabled,
.select:disabled,
.textarea:disabled {
  background: var(--gray-200);
  color: var(--gray-400);
  cursor: not-allowed;
  opacity: 0.7;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .container {
    max-width: 1000px;
    padding: 8px;
  }

  .propertyForm {
    padding: 12px;
  }

  .heading {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 900px;
    padding: 6px;
  }

  .propertyForm {
    padding: 10px;
  }

  .heading {
    font-size: 20px;
  }

  .formGroupSideBySide {
    grid-template-columns: 1fr;
  }

  .paymentPlanGroup {
    grid-template-columns: 1fr;
  }

  .coordinatesGroup {
    grid-template-columns: 1fr;
  }

  .imagesGrid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 5px;
  }

  .propertyForm {
    padding: 8px;
    border-radius: var(--border-radius-md);
  }

  .heading {
    font-size: 18px;
  }

  .formGroup {
    margin-bottom: 8px;
  }

  .input,
  .select,
  .textarea {
    font-size: 12px;
    padding: 6px 10px;
  }

  .imagesGrid {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }

  .button {
    padding: 8px 15px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 4px;
  }

  .heading {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .propertyForm {
    padding: 6px;
    box-shadow: var(--shadow-sm);
  }

  .formGroup {
    margin-bottom: 6px;
  }

  .label {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .input,
  .select,
  .textarea {
    font-size: 11px;
    padding: 5px 8px;
  }

  .textarea {
    min-height: 60px;
  }

  .button {
    padding: 6px 12px;
    font-size: 11px;
  }

  .paymentPlanHeading {
    font-size: 14px;
  }

  .errorText {
    font-size: 10px;
  }

  .errorMessage {
    font-size: 12px;
    padding: 6px;
  }

  .imagesGrid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
  }

  .imagePreviewItem {
    padding: 6px;
  }

  .removeImageBtn {
    width: 18px;
    height: 18px;
    font-size: 10px;
  }
}

/* High-Resolution Displays */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .propertyForm {
    box-shadow: var(--shadow-lg);
  }

  .input,
  .select,
  .textarea {
    border-width: 0.5px;
  }

  .button {
    box-shadow: var(--shadow-md);
  }
}

/* Print Styling */
@media print {
  .container {
    padding: 0;
    background: none;
  }

  .propertyForm {
    box-shadow: none;
    border: none;
    padding: 0;
  }

  .button,
  .removeImageBtn,
  .imagePreview,
  .imagesGrid {
    display: none;
  }

  .formGroup,
  .formGroupSideBySide {
    margin-bottom: 8px;
  }

  .input,
  .select,
  .textarea {
    border: 1px solid #000;
    background: none;
    box-shadow: none;
  }
}

/* Additional Utility Classes */
.required::after {
  content: '*';
  color: var(--error-color);
  margin-left: 4px;
  font-size: 13px;
  font-weight: bold;
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.rounded-sm {
  border-radius: var(--border-radius-sm);
}

.rounded-md {
  border-radius: var(--border-radius-md);
}

.rounded-lg {
  border-radius: var(--border-radius-lg);
}

.transition-all {
  transition: var(--transition);
}

.bg-gray-50 {
  background-color: var(--gray-100);
}

.text-gray-600 {
  color: var(--gray-600);
}

.text-gray-800 {
  color: var(--gray-800);
}

.border-gray-200 {
  border-color: var(--gray-200);
}

/* Accessibility Enhancements */
.input:focus,
.select:focus,
.textarea:focus,
.button:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Form Section Dividers */
.formGroup:not(:last-child)::after,
.formGroupSideBySide:not(:last-child)::after {
  content: '';
  display: block;
  width: 80%;
  height: 1px;
  background: var(--gray-200);
  margin: 8px auto 0;
}

/* Enhanced Hover Effects */
.input:hover,
.select:hover,
.textarea:hover {
  border-color: var(--gray-300);
  box-shadow: 0 0 0 2px rgba(203, 213, 224, 0.3);
}

/* Custom Focus Rings */
.input:focus-visible,
.select:focus-visible,
.textarea:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.4);
}

/* Gradient Backgrounds for Sections */
.paymentPlanGroup,
.coordinatesGroup,
.seoSection,
.reraSection {
  background: linear-gradient(145deg, var(--gray-100), var(--white));
}

/* Improved Typography */
.label,
.checkboxLabel,
.paymentPlanHeading {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Enhanced Image Previews */
.imagePreviewItem::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  opacity: 0;
  transition: var(--transition);
}

.imagePreviewItem:hover::before {
  opacity: 1;
}

/* Subtle Animation for Form Load */
.propertyForm {
  animation: fadeIn 0.5s ease;
}

/* Enhanced Button Ripple Effect */
.button:active::after {
  width: 300px;
  height: 300px;
  transition: width 0.2s ease, height 0.2s ease;
}

/* Custom Tooltip for Help Text */
.helpText {
  position: relative;
}

.helpText:hover::after {
  content: 'Edit this field as needed';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: var(--gray-800);
  color: var(--white);
  padding: 4px 8px;
  border-radius: var(--border-radius-sm);
  font-size: 10px;
  white-space: nowrap;
  z-index: 10;
}

/* Final Touches */
.button {
  margin-top: 15px;
}