/* ========================================================================== */
/* Base Styles */
/* ========================================================================== */

.adminPanel {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Georgia', serif;
  background: #f8f9fa;
  min-height: 100vh;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  overflow-x: hidden;
  position: relative;
  margin-top: 70px;
}

/* ========================================================================== */
/* Header Styles */
/* ========================================================================== */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background: #ffffff;
  border-bottom: 1px solid #e9ecef;
  border-radius: 12px 12px 0 0;
  position: sticky;
  top: 0;
  z-index: 20;
}

.heading {
  font-size: 2.5rem;
  color: #2d3748;
  margin: 0;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.2;
}

.heading--small {
  font-size: 1.75rem;
}

.heading--large {
  font-size: 3rem;
}

.logoutButton {
  background: #e53e3e;
  color: white;
  border: none;
  padding: 10px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: background 0.2s ease, transform 0.2s ease;
}

.logoutButton:hover {
  background: #c53030;
  transform: translateY(-2px);
}

.logoutButton:active {
  transform: translateY(0);
}

/* ========================================================================== */
/* Tab Container */
/* ========================================================================== */

.tabContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 12px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.tab {
  padding: 12px 20px;
  font-size: 1rem;
  color: #4a5568;
  background: #edf2f7;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  transition: background 0.2s ease, transform 0.2s ease;
}

.tab:hover {
  background: #e2e8f0;
  transform: translateY(-2px);
}

.tab:active {
  transform: translateY(0);
}

.activeTab {
  background: #3182ce;
  color: white;
  box-shadow: 0 4px 12px rgba(49, 130, 206, 0.2);
  transform: translateY(-2px);
}

.activeTab:hover {
  background: #2b6cb0;
}

.tab--small {
  padding: 8px 16px;
  font-size: 0.9rem;
}

.tab--large {
  padding: 16px 28px;
  font-size: 1.1rem;
}

/* ========================================================================== */
/* Tab Content */
/* ========================================================================== */

.tabContent {
  background: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  animation: slideIn 0.3s ease-out;
}

.tabContent--compact {
  padding: 15px;
}

.tabContent--wide {
  padding: 35px;
}

/* ========================================================================== */
/* Section Heading */
/* ========================================================================== */

.sectionHeading {
  font-size: 1.75rem;
  color: #2d3748;
  margin-bottom: 20px;
  font-weight: 600;
  border-bottom: 2px solid #3182ce;
  padding-bottom: 8px;
  line-height: 1.3;
}

.sectionHeading--small {
  font-size: 1.5rem;
}

.sectionHeading--large {
  font-size: 2rem;
}

/* ========================================================================== */
/* Table Styles */
/* ========================================================================== */

.propertiesTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #ffffff;
}

.tableHeader {
  background: #2d3748;
  color: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.tableHeader th {
  padding: 15px;
  text-align: left;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  border-bottom: 2px solid #4a5568;
}

.tableHeader--light {
  background: #718096;
}

.tableRow {
  background: #ffffff;
  transition: background 0.2s ease, transform 0.2s ease;
}

.tableRow:hover {
  background: #f7fafc;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.tableRow td {
  padding: 15px;
  font-size: 0.95rem;
  color: #4a5568;
  border-bottom: 1px solid #e9ecef;
}

.tableRow--highlight {
  background: #fefcbf;
}

/* ========================================================================== */
/* Action Buttons */
/* ========================================================================== */

.actions {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;
}

.approveButton {
  background: #38a169;
  color: white;
  padding: 8px 18px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.approveButton:hover {
  background: #2f855a;
  transform: translateY(-2px);
}

.approveButton:active {
  transform: translateY(0);
}

.editButton {
  background: #3182ce;
  color: white;
  padding: 8px 18px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.editButton:hover {
  background: #2b6cb0;
  transform: translateY(-2px);
}

.editButton:active {
  transform: translateY(0);
}

.deleteButton {
  background: #e53e3e;
  color: white;
  padding: 8px 18px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.deleteButton:hover {
  background: #c53030;
  transform: translateY(-2px);
}

.deleteButton:active {
  transform: translateY(0);
}

/* Button Variants */
.button--small {
  padding: 6px 14px;
  font-size: 0.85rem;
}

.button--large {
  padding: 12px 24px;
  font-size: 1.1rem;
}

.possessionDateInput {
  margin-top: 10px;
}

.possessionDateInput label {
  font-size: 0.9rem;
  color: #4a5568;
  font-weight: 500;
}

.possessionDateInput input {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  font-size: 0.9rem;
}
.possessionDateWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.okButton {
  background: #38a169;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.okButton:hover {
  background: #2f855a;
  transform: translateY(-2px);
}

.okButton:active {
  transform: translateY(0);
}

/* ========================================================================== */
/* Status Buttons */
/* ========================================================================== */

.statusButtons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.statusButton {
  padding: 6px 14px;
  font-size: 0.85rem;
  color: #4a5568;
  background: #edf2f7;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.2s ease, transform 0.2s ease;
}

.statusButton:hover {
  background: #e2e8f0;
  transform: translateY(-1px);
}

.statusButton:active {
  transform: translateY(0);
}

.statusActive {
  background: #3182ce;
  color: white;
  transform: translateY(-1px);
}

.statusActive:hover {
  background: #2b6cb0;
}

/* ========================================================================== */
/* Pagination */
/* ========================================================================== */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 25px;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
}

.paginationButton {
  background: #3182ce;
  color: white;
  padding: 8px 18px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.paginationButton:hover:not(:disabled) {
  background: #2b6cb0;
  transform: translateY(-2px);
}

.paginationButton:disabled {
  background: #a0aec0;
  cursor: not-allowed;
  transform: none;
}

.paginationButton:active {
  transform: translateY(0);
}

.pageInfo {
  font-size: 1rem;
  color: #4a5568;
  font-weight: 500;
  padding: 6px 12px;
  background: #edf2f7;
  border-radius: 8px;
}

/* ========================================================================== */
/* Edit Header */
/* ========================================================================== */

.editHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  background: #f7fafc;
  border-radius: 10px;
  flex-wrap: wrap;
}

.cancelButton {
  background: #e53e3e;
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.cancelButton:hover {
  background: #c53030;
  transform: translateY(-2px);
}

.cancelButton:active {
  transform: translateY(0);
}

/* ========================================================================== */
/* Loading Overlay */
/* ========================================================================== */

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #e2e8f0;
  border-top: 5px solid #3182ce;
  border-radius: 50%;
  animation: spin 0.8s ease-in-out infinite;
}

.spinner--small {
  width: 30px;
  height: 30px;
  border-width: 3px;
}

.spinner--large {
  width: 70px;
  height: 70px;
  border-width: 6px;
}

/* ========================================================================== */
/* Session Timeout */
/* ========================================================================== */

.sessionTimeout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease-out;
}

.timeoutContent {
  background: #ffffff;
  padding: 25px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  min-width: 320px;
  animation: scaleIn 0.3s ease-out;
}

.timeoutContent h3 {
  font-size: 1.5rem;
  color: #2d3748;
  margin: 0 0 15px;
}

.timeoutContent p {
  font-size: 1rem;
  color: #4a5568;
  margin: 0 0 20px;
}

.timeoutActions {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.stayButton {
  background: #38a169;
  color: white;
  border: none;
  padding: 10px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.stayButton:hover {
  background: #2f855a;
  transform: translateY(-2px);
}

.stayButton:active {
  transform: translateY(0);
}

/* ========================================================================== */
/* Message Modal */
/* ========================================================================== */

.messageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.messageModalContent {
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: scaleIn 0.3s ease-out;
}

.messageModalContent h3 {
  margin: 0 0 15px;
  color: #2d3748;
  font-size: 1.5rem;
  font-weight: 600;
}

.messageModalContent p {
  margin: 0 0 20px;
  color: #4a5568;
  font-size: 1rem;
  line-height: 1.6;
}

.closeButton {
  background: #e53e3e;
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.closeButton:hover {
  background: #c53030;
  transform: translateY(-2px);
}

.closeButton:active {
  transform: translateY(0);
}

/* ========================================================================== */
/* Debug Info */
/* ========================================================================== */

.debugInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #f7fafc;
  border-radius: 10px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.debugInfo p {
  margin: 0;
  font-size: 0.9rem;
  color: #718096;
}

/* ========================================================================== */
/* Button Group */
/* ========================================================================== */

.buttonGroup {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;
}

.exportButton {
  background: #38a169;
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background 0.2s ease, transform 0.2s ease;
}

.exportButton:hover {
  background: #2f855a;
  transform: translateY(-2px);
}

.exportButton:active {
  transform: translateY(0);
}

/* ========================================================================== */
/* No Properties */
/* ========================================================================== */

.noProperties {
  text-align: center;
  font-size: 1.1rem;
  color: #718096;
  padding: 25px;
  background: #f7fafc;
  border-radius: 10px;
  margin: 15px 0;
}

/* ========================================================================== */
/* Message Cell */
/* ========================================================================== */

.messageCell {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.2s ease;
}

.messageCell:hover {
  color: #3182ce;
}

/* ========================================================================== */
/* Error Message */
/* ========================================================================== */

.errorMessage {
  background: #fef2f2;
  color: #c53030;
  padding: 15px 20px;
  border-radius: 10px;
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 2px 8px rgba(229, 62, 62, 0.1);
  animation: slideIn 0.3s ease-out;
}

.errorMessage::before {
  content: "⚠️";
  font-size: 1.2rem;
}

.errorMessage--small {
  padding: 10px 15px;
  font-size: 0.9rem;
}

.errorMessage--large {
  padding: 20px 25px;
  font-size: 1.1rem;
}

/* ========================================================================== */
/* Utility Classes */
/* ========================================================================== */

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.shadow-md {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.shadow-lg {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.rounded-sm {
  border-radius: 6px;
}

.rounded-md {
  border-radius: 10px;
}

.rounded-lg {
  border-radius: 14px;
}

.bg-primary {
  background: #3182ce;
  color: white;
}

.bg-success {
  background: #38a169;
  color: white;
}

.bg-danger {
  background: #e53e3e;
  color: white;
}

.text-primary {
  color: #3182ce;
}

.text-success {
  color: #38a169;
}

.text-danger {
  color: #e53e3e;
}

.padding-sm {
  padding: 10px;
}

.padding-md {
  padding: 20px;
}

.padding-lg {
  padding: 30px;
}

.margin-sm {
  margin: 10px;
}

.margin-md {
  margin: 20px;
}

.margin-lg {
  margin: 30px;
}

/* ========================================================================== */
/* Animations */
/* ========================================================================== */

@keyframes slideIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* ========================================================================== */
/* Responsive Design */
/* ========================================================================== */

@media (max-width: 1400px) {
  .adminPanel {
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .adminPanel {
    padding: 15px;
  }

  .heading {
    font-size: 2rem;
  }

  .tabContainer {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .tableRow td {
    padding: 12px;
  }
}

@media (max-width: 992px) {
  .tabContainer {
    grid-template-columns: 1fr;
  }

  .tab, .activeTab {
    padding: 10px 15px;
  }

  .propertiesTable {
    display: block;
    overflow-x: auto;
  }

  .tableHeader th, .tableRow td {
    min-width: 120px;
    font-size: 0.9rem;
  }

  .actions {
    gap: 8px;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 12px;
    text-align: center;
    padding: 10px 20px;
  }

  .sectionHeading {
    font-size: 1.5rem;
  }

  .actions, .statusButtons {
    flex-direction: column;
    align-items: flex-start;
  }

  .editHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .tabContent {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.75rem;
  }

  .sectionHeading {
    font-size: 1.25rem;
  }

  .tableHeader th, .tableRow td {
    padding: 10px;
    font-size: 0.85rem;
  }

  .pagination {
    flex-direction: column;
    gap: 12px;
  }

  .approveButton, .editButton, .deleteButton {
    padding: 6px 14px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .adminPanel {
    padding: 10px;
  }

  .tab {
    font-size: 0.9rem;
  }

  .timeoutContent {
    min-width: 280px;
    padding: 20px;
  }
}

/* ========================================================================== */
/* Accessibility */
/* ========================================================================== */

:focus {
  outline: 2px solid #3182ce;
  outline-offset: 2px;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
}

/* ========================================================================== */
/* Scrollbar */
/* ========================================================================== */

.propertiesTable::-webkit-scrollbar {
  height: 8px;
}

.propertiesTable::-webkit-scrollbar-track {
  background: #edf2f7;
  border-radius: 4px;
}

.propertiesTable::-webkit-scrollbar-thumb {
  background: #a0aec0;
  border-radius: 4px;
}

.propertiesTable::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

.adminPanel::-webkit-scrollbar {
  width: 10px;
}

.adminPanel::-webkit-scrollbar-track {
  background: #f8f9fa;
}

.adminPanel::-webkit-scrollbar-thumb {
  background: #a0aec0;
  border-radius: 5px;
}

.adminPanel::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

/* ========================================================================== */
/* High Contrast Mode */
/* ========================================================================== */

@media (prefers-contrast: high) {
  .adminPanel {
    background: #ffffff;
    color: #000000;
    border: 2px solid #000000;
  }

  .tab {
    background: #cccccc;
    color: #000000;
    border: 1px solid #000000;
  }

  .activeTab {
    background: #0000ff;
    color: #ffffff;
    border: 1px solid #000000;
  }

  .tableHeader {
    background: #000000;
    color: #ffffff;
  }

  .tableRow {
    border: 1px solid #000000;
  }
}

/* ========================================================================== */
/* Print Styles */
/* ========================================================================== */

@media print {
  .adminPanel {
    box-shadow: none;
    border: none;
  }

  .tabContainer, .actions, .statusButtons, .pagination {
    display: none;
  }

  .tableHeader {
    background: #ffffff;
    color: #000000;
    border-bottom: 2px solid #000000;
  }

  .tableRow {
    border-bottom: 1px solid #000000;
  }
}