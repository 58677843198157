/* Gallery container */
.galleryContainer {
    height: 100vh;
    width: 100vw;
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow: hidden;
    margin: 0;
    position: relative;
    transition: all 0.3s ease;
}

.galleryContainer.fullscreen {
    padding: 20px;
}

/* White margin-top area */
.galleryContainer::before {
    content: '';
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    height: 70px;
    background: #fff;
    z-index: -1;
}

/* Main image container */
.imageContainer {
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 70vh;
    display: flex;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    touch-action: none; /* Prevents default touch behaviors */
    -webkit-user-select: none;
    -ms-user-select: none;
}

.galleryContainer.fullscreen .imageContainer {
    max-width: 100%;
    height: calc(100vh - 70px);
}

/* Image wrapper for zoom and drag */
.imageWrapper {
    transition: transform 0.1s ease-out; /* Smoother dragging */
    transform-origin: center center; /* Ensure scaling from center */
    -webkit-user-select: none;
    user-select: none;
    touch-action: pan-x pan-y pinch-zoom; /* Enable pinch zoom on mobile */
}

/* Floor plan image */
.floorPlanImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.1);
    user-select: none;
    max-width: none; /* Allow image to exceed container when zoomed */
    max-height: none;
}

/* Controls */
.controls {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 50px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

/* Navigation buttons */
.navButton {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
}

.navButton:hover {
    background: rgba(255, 255, 255, 0.4);
}

/* Image info */
.imageInfo {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

/* Zoom controls container */
.zoomControls {
    display: flex;
    gap: 10px;
}

/* Zoom buttons */
.zoomButton {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
}

.zoomButton:hover {
    background: rgba(255, 255, 255, 0.4);
}

/* Fullscreen button */
.fullscreenButton {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: #fff;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background 0.3s ease;
}

.fullscreenButton:hover {
    background: rgba(255, 255, 255, 0.4);
}

/* Close button for fullscreen */
.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
    z-index: 1000;
}

.closeButton:hover {
    background: rgba(255, 255, 255, 0.4);
}

/* Thumbnails container */
.thumbnailsContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    overflow-x: auto;
}

/* Thumbnail items */
.thumbnailItem {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
    overflow: hidden;
    transition: border 0.3s ease, transform 0.3s ease;
}

.thumbnailItem.active {
    border-color: #007bff;
    transform: scale(1.05);
}

.thumbnailImage {
    width: 120px;
    height: 80px;
    object-fit: cover;
}

/* Loading and no floor plans states */
.loading,
.noFloorPlans {
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    padding: 50px;
    font-weight: 500;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .galleryContainer {
        padding: 10px;
    }

    .galleryContainer::before {
        height: 50px;
        top: -50px;
    }

    .imageContainer {
        height: 60vh;
        margin-top: 50px;
    }

    .controls {
        padding: 0 10px;
        flex-wrap: wrap;
        gap: 10px;
    }

    .imageInfo {
        font-size: 0.9rem;
        flex-direction: column;
        gap: 10px;
    }

    .zoomControls {
        gap: 5px;
    }

    .thumbnailsContainer {
        padding: 5px;
        gap: 5px;
    }

    .thumbnailImage {
        width: 80px;
        height: 60px;
    }

    .navButton, .zoomButton {
        padding: 6px;
    }

    .fullscreenButton {
        padding: 6px 12px;
        font-size: 0.9rem;
    }
}